
import All from "./components/All";
import All_certificates from "./components/All_certificates";
import Contact from "./components/Contact";

import { BrowserRouter as Router, Route, Switch, Routes, BrowserRouter } from 'react-router-dom';
import Service from "./components/Service";
import { useEffect } from "react";

function App () {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<All />} />
          <Route path="/services" element={<All_certificates />} />
          <Route path="/services/service/:keyword" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
