import React from 'react'
import loading from '../assest/loading.gif'

function Loader () {
    return (
        <div className="loader">
            <img style={{ width: "180%" }} src={loading} alt="" />
        </div>
    )
}

export default Loader
