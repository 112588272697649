import React from 'react'
import Navbar from './Navbar'
import Home from './Home'
import About from './About'
import Certificates from './Certificates'
import Memories from './Memories'
import Contact from './Contact'
import Footer from './Footer'
import Loader from './Loader'

function All () {
    return (
        <>
            <Loader />
            <Navbar />
            <Home />
            <About />
            <Certificates />
            <Memories />
            <Footer />
        </>
    )
}

export default All
