import { configureStore } from "@reduxjs/toolkit"
import showSlice from "./slices/show-slice"
import aboutSlice from "./slices/about-slice"
import reviewsSlice from "./slices/reviews-slice"
import visitorSlice from "./slices/visitor-slice"
import colorSlice from "./slices/color-slice"
import logoSlice from "./slices/logo-slice"
import servicesSlice from "./slices/services-slice"



export const store = configureStore({
    reducer: {
        show: showSlice,
        about: aboutSlice,
        reviews: reviewsSlice,
        visitor: visitorSlice,
        color: colorSlice,
        logo: logoSlice,
        services: servicesSlice,
    }
})