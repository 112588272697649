import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import service from '../assest/des1.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchServices } from '../rtk/slices/services-slice';

function Service () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { keyword } = useParams();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };


    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);
    const color = useSelector((state) => state.color.color);

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchServices());

    }, [])

    return (
        <section className='projects service' id='projects'>
            <div className="container">
                <h2 className="title">
                    <span>الخدمات</span>
                    <p style={{ textTransform: "capitalize" }}>{keyword}</p>
                </h2>

                {services.map((service) => {
                    return (
                        service['keyword'] === keyword ?
                            <div className="card-service">
                                <div className="img">
                                    <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img_service}`} alt="" />
                                </div>
                                <div className="content">
                                    <div className="box">
                                        <h3>ما هي خدمة {keyword}؟</h3>
                                        <p>
                                            {service.whats_service.split(',').map((what) => {
                                                return (
                                                    <>
                                                        <span>{what}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <div className="box">
                                        <h3>المميزات</h3>
                                        <p>
                                            {service.features_service.split(',').map((feature) => {
                                                return (
                                                    <>
                                                        <span>{feature}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <div className="box">
                                        <h3>الاسعار</h3>
                                        <p className='priceP'>
                                            {service.prices_service.split(',').map((price) => {
                                                return (
                                                    <>
                                                        <span className='priceSpan'>{price}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <div style={{ textAlign: "center" }} class="btns"><button class="button type1"><span class="btn-txt"><Link to={`/contact`}>اطلب الان</Link></span></button></div>
                                </div>
                            </div>
                            : null
                    )
                })}


                <Link className='exit' to={`/services`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Service
